import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import hex2rgba from 'hex2rgba';
import { color } from '../../styles/theme';
import { WithUnderline } from '../../styles/link';
import arrow from '../../images/arrow-yellow.svg';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: ${hex2rgba(color('black100'), 0.5)};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 15px;
  height: 100%;
  
  @media (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Inner = styled.div`
  max-width: 990px;
`;

const Content = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 1.8;
  letter-spacing: 0.1px;
  color: ${color('white')};
  
  @media (min-width: 768px) {
    font-size: 34px;
  }
  
  .lowercase {
    text-transform: lowercase;
  }
  
  p {
    margin-bottom: 0;
  }
`;

const Button = styled(WithUnderline)`
  padding: 0;
  border: 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.8;
  color: ${color('lemon')};
  background-color: transparent;
  cursor: pointer;
  
  @media (min-width: 768px) {
    font-size: 34px;
  }
  
  &:after {
    bottom: 10px;
  }

  img {
    margin-left: 15px;
    width: 1em;
    height: 1em;
    vertical-align: middle;
  }
`;

function getPositions(data) {
  return data.reduce((acc, cur) => {
    const { totalCount, fieldValue } = cur;
    const propName = fieldValue.replace(/\s|developer/gi, '').toLowerCase();

    if (propName.includes('qa')) {
      return { ...acc, qaengineer: (acc.qaengineer || 0) + totalCount };
    }

    return {
      ...acc,
      [propName]: totalCount,
    };
  }, {});
}

function pluralize(values) {
  return (num) => num > 1 ? values[1] : values[0];
}

class TeamDescription extends React.Component {
  state = {
    show: true,
  };

  handleClick = () => {
    const { handleChange } = this.props;

    this.setState({ show: false }, () => {
      handleChange(this.state.show); // eslint-disable-line
    });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
        query {
          allMarkdownRemark (
            filter: {
              fields: {slug: {regex: "//teams//"}},
              frontmatter: {
                visibility: {eq: "yes"},
                position: {regex: "/developer|project manager|qa/i"}
              }
            }
          ) {
            group(field: frontmatter___position) {
              totalCount
              fieldValue
            }
          }
        }`
        }
        render={(data) => {
          const { show } = this.state;
          const {
            android,
            backend,
            frontend,
            ios,
            fullstack,
            projectmanager,
            qaengineer,
          } = getPositions(data.allMarkdownRemark.group);
          // TODO: Maybe this is not the best solution, but I didn't think of
          //  anything better that day. I hope you will do better and you will
          //  not be ashamed as I am.
          const developer = pluralize(['developer', 'developers']);
          const engineer = pluralize(['engineer', 'engineers']);
          const manager = pluralize(['manager', 'managers']);

          return (
            <>
              {show
                ? (
                  <Wrapper>
                    <Container>
                      <Inner>
                        <Content>
                          <p>
                            {fullstack} full stack software {engineer(fullstack)},&nbsp;
                            {backend} backend {developer(backend)},&nbsp;
                            {frontend} frontend {developer(frontend)},&nbsp;
                            {android} android {developer(android)},&nbsp;
                            {ios} iOS {developer(ios)},&nbsp;
                            {qaengineer} QA {engineer(qaengineer)},&nbsp;
                            {projectmanager} project {manager(projectmanager)},
                            1 office manager, 1 attorney, 1 CTO, 1 delivery manager,
                            1 managing partner, 1 founder.&nbsp;
                            <Button as="button" onClick={this.handleClick}>
                              <span>Meet Our Team</span>
                              <img src={arrow} alt="" />
                            </Button>
                          </p>
                        </Content>
                      </Inner>
                    </Container>
                  </Wrapper>
                ) : null
              }
            </>
          );
        }}
      />
    );
  }
}

export default TeamDescription;
