import React from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import { gray, themeColor } from '../styles/theme';

const Text = styled.section`
  padding: 80px 15px;
  background-color: ${gray(100)};

  @media (min-width: 768px) {
    padding-top: 160px;
    padding-bottom: ${(props) => props.marginBottom ? `${props.marginBottom}px` : '160px'};
  }
  
  &.with-image {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const TextInner = styled.div`
  margin: 0 auto;
  max-width: 920px;

  h2 {
    margin-bottom: 60px;
    font-size: 42px;
    color: ${themeColor('primary')}
  }

  p, ul, ol, li {
    font-size: 22px;
    line-height: 1.75;
    letter-spacing: -0.3px;
    color: ${themeColor('primary')};

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  p {
    margin-bottom: 60px;
  }

  p:last-child {
    margin-bottom: 0;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    
    &:hover {
      color: ${themeColor('accent')};
    }
  }
  
   .with-image & {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     
     @media (max-width: 639.98px) {
       flex-direction: column;
     }
 
     & > * {
       flex: 1 1;
     }
      
     p {
       flex-basis: auto;
       font-style: italic;
       font-size: 20px;
       margin-bottom: 0;
       
       @media (max-width: 639.98px) {
          order: 1;
       }
       
       &:first-child {
         max-width: 545px;
         
         @media (max-width: 639.98px) {
          order: 2;
         }
       }
       
       &:last-child {
         @media (max-width: 639.98px) {
           width: 100%;
           margin-bottom: 30px; 
         }
       }
     }
     
     .gatsby-resp-image-wrapper {
        max-width: 180px !important;
     }
  }
`;

const TextBlock = ({ data, order, withImage, marginBottom }) => {
  const [content] = data.edges.filter(({ node }) => node.frontmatter.order === order);

  if (!content) return null;

  return (
    <Text data-target="clients" className={withImage ? 'with-image' : null} marginBottom={marginBottom}>
      <TextInner dangerouslySetInnerHTML={{ __html: content.node.html }} />
    </Text>
  );
};

export default TextBlock;

export const query = graphql`
  fragment MarkdownTextNode on MarkdownRemarkEdge {
    node {
      frontmatter {
        order
      }
      html
    }
  }
`;
