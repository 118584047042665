import React from 'react';
import { graphql } from 'gatsby';
import MediaQuery from 'react-responsive';

import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import HomeHero from '../components/hero/HomeHero';
import ClientsList from '../components/ClientsList';
import TextBlock from '../components/TextBlock';
import ArticlesList from '../components/articles/ArticlesList';
import Contact from '../components/Contact';
import MoreArticles from '../components/articles/MoreArticles';
import TeamsList from '../components/teams/TeamsList';
import TeamsListUS from '../components/teams/TeamsListUS';

export const getSEOTextByTag = (data, tag) => {
  const [result] = data.edges.filter(({ node }) => node.frontmatter.title === tag);
  return result ? result.node.frontmatter.text : null;
};

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title={getSEOTextByTag(data.seo, 'title') || 'Seattle-based Software Services'}
      description={getSEOTextByTag(data.seo, 'description') || 'We build custom web and mobile apps for business.'}
      keywords={['web', 'apps', 'development']}
    />
    <HomeHero />
    <main>
      <MediaQuery minDeviceWidth={768}>
        <ClientsList />
      </MediaQuery>
      <TextBlock data={data.textBlocks} order={1} />
      <TextBlock data={data.textBlocks} order={2} withImage />
      <ArticlesList data={data.caseStudies} />
      <MoreArticles />
      <TextBlock data={data.textBlocks} order={3} marginBottom={60} />
      <TeamsListUS />
      <TeamsList data={data.teams} />
      <Contact data={data.offices} />
      <MediaQuery maxDeviceWidth={767.98}>
        <ClientsList />
      </MediaQuery>
    </main>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    textBlocks: allMarkdownRemark(
      limit: 3
      filter: {
        fields: {
          slug: {regex: "//home//"}
        }
        frontmatter: {
          visibility: {eq: "yes"}
        }
      }
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        ...MarkdownTextNode
      }
    }

    caseStudies: allMarkdownRemark(
      limit: 3,
      filter: {
        fileAbsolutePath: {regex: "//case-studies//"}
        frontmatter: {
          visibility: {eq: "yes"},
          home: {eq: "yes"}
        }
      },
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        ...MarkdownPostNode
      }
    }

    offices: allMarkdownRemark(
      limit: 2,
      filter: {
        fields: {
          slug: {regex: "//addresses//"}
        }
        frontmatter: {
          visibility: {eq: "yes"}
        }
      },
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        ...MarkdownMapNode
      }
    }

    teams: allMarkdownRemark(
      filter: {
        fields: {
          slug: {regex: "//teams//"}
        }
        frontmatter: {
          visibility: {eq: "yes"}
        }
      },
      sort: {fields: [frontmatter___order], order: ASC}
    ) {
      edges {
        ...MarkdownMemberNode
      }
    }
    seo: allMarkdownRemark(
      limit: 10
      filter: {
        fields: {
          slug: {regex: "//seo//"}
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            text
          }
        }
      }
    }
  }
`;
