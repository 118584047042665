import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Member from './Member';
import TeamDescription from './TeamDescription';
import { color } from '../../styles/theme';

const Wrapper = styled.section`
  position: relative;
  padding: 35px 15px 45px;
  max-height: ${({ collapsed }) => collapsed ? '580px' : '100%'};
  background-color: ${color('dark')};
  overflow: hidden;
  transition: all .3s ease-in-out;
  
  @media (min-width: 768px) {
    max-height: ${({ collapsed }) => collapsed ? '740px' : '100%'};
    padding-left: 45px;
    padding-right: 45px;
  }
  
  @media (min-width: 1024px) {
    max-height: ${({ collapsed }) => collapsed ? '760px' : '100%'};
  }
  
  @media (min-width: 1920px) {
    max-height: ${({ collapsed }) => collapsed ? '1000px' : '100%'};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1260px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0;
  
  @media (min-width: 1440px) {
    margin: 0;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: calc(100% / 3);
  padding: 15px 10px;
  
  @media (min-width: 768px) {
    flex-basis: calc(100% / 4);
    padding-top: 35px;
    padding-bottom: 35px;
    min-width: 160px;
  }
  
  @media (min-width: 1024px) {
    flex-basis: calc(100% / 5);
  }
  
  @media (min-width: 1200px) {
    flex-basis: calc(100% / 7);
  }
`;

class TeamsList extends React.Component {
  state = {
    collapsed: true,
  };

  handleChange = (value) => {
    this.setState({ collapsed: value });
  };

  render() {
    const { data } = this.props;
    const { collapsed } = this.state;

    return (
      <Wrapper data-target="team" collapsed={collapsed}>
        <Container>
          <List>
            {data.edges.map(({ node }) => (
              <Item key={node.id}>
                <Member data={node.frontmatter} collapsed={collapsed} />
              </Item>
            ))}
          </List>
        </Container>
        <TeamDescription handleChange={this.handleChange} />
      </Wrapper>
    );
  }
}

export default TeamsList;

export const query = graphql`
  fragment MarkdownMemberNode on MarkdownRemarkEdge {
    node {
      id
      frontmatter {
        fullname
        display_name
        position
        linkedin
        picture {
          childImageSharp {
            resize(width: 235, height: 235, quality: 90, cropFocus: CENTER) {
              src
            }
          }
        }
      }
    }
  }
`;
