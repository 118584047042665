import React from 'react';
import { graphql, navigateTo } from 'gatsby';

import ArticlePreview from './ArticlePreview';

const ArticlesList = ({ data, type }) => (
  <section data-target="work" className={`${type}-template`}>
    { data ? data.edges.map(({ node }, i) => (
      <ArticlePreview key={node.id} data={node} reverse={i % 2 !== 0} />
    )) : navigateTo('/') }
  </section>
);

export default ArticlesList;

export const query = graphql`
  fragment MarkdownPostNode on MarkdownRemarkEdge {
    node {
      id
      frontmatter {
        title
        name
        label
        date(formatString: "MMMM D, YYYY")
        excerpt
        logo {
          publicURL
        }
        preview_image {
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`;
