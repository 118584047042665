import React from 'react';
import styled from 'styled-components';
import { themeColor } from '../../styles/theme';

const Container = styled.div`
    margin: 0 auto 60px;
    max-width: 1260px;
`;

const TextInner = styled.div`
  margin: 0 auto;
  max-width: 920px;

  @media (max-width: 950px) {
    padding-left: 15px;
    padding-right: 15px;
  }

    p, ul, ol, li {
        font-size: 22px;
        line-height: 1.75;
        letter-spacing: -0.3px;
        color: ${themeColor('primary')};

        @media (min-width: 768px) {
            font-size: 24px;
        }
    }

    p {
        margin-bottom: 60px;
    }

    p:last-child {
        margin-bottom: 0;
    }
`;

const MemberListStyle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Item = styled.div`
 min-width: 180px;
 width: 22%;
 max-width: 180px;
 margin-bottom: 30px;
  
 img {
   border-radius: 50%;
   max-width: 170px;
   margin-bottom: 15px;
 }
`;

const Member = styled.div`
 margin-bottom: 5px;
`;

const Name = styled.div`
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.3px;
  color: ${themeColor('primary')};
  text-align: center;
`;

const Position = styled.div`
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.3px;
  color: ${themeColor('primary')};
  text-align: center;
`;

const TeamsListUSMembers = [
  {
    id: 1,
    name: 'Yuriy Kurat',
    position: 'Founder, CEO',
    image: 'images/yuriy_kurat-us.jpg',
  },
  {
    id: 2,
    name: 'Daniel Spils',
    position: 'Managing Partner',
    image: 'images/daniel_spils-us.jpg',
  },
  {
    id: 3,
    name: 'Rebecca Staffel',
    position: 'Managing Partner',
    image: 'images/rebecca-staffel-us.jpg',
  },
  {
    id: 4,
    name: 'Peter Hilgendorf',
    position: 'Design Partner',
    image: 'images/peter-hilgendorf-us.jpg',
  },
];

const TeamsListUS = () => (
  <>
    <TextInner>
      {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
      <Container>
        <MemberListStyle>
          {TeamsListUSMembers.map((node) => (
            <Item key={node.id}>
              <img
                src={node.image}
                alt={node.name}
              />
              <Member>
                <Name>{node.name}</Name>
                <Position>{node.position}</Position>
              </Member>
            </Item>
          ))}
        </MemberListStyle>
      </Container>
    </TextInner>
  </>
);

export default TeamsListUS;
