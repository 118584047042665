import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { themeColor, color } from '../../styles/theme';
import { MainNav } from '../navigation/MainNav';
import { Header } from '../Header';
import logo from '../../images/logo-full.png';
import scrollArrow from '../../images/scroll-arrow.svg';

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 35px 15px;
  width: 100%;
  min-height: 100vh;
  background-color: ${themeColor('primary')};
  overflow: hidden;

  @media (min-width: 768px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @media (min-width: 768px) and (max-height: 650px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const Logo = styled.img`
  position: relative;
  z-index: 10;
  max-width: 155px;
  height: auto;

  @media (min-width: 768px) {
    max-width: 185px;
  }
`;

const Heading = styled.div`
  margin-top: 30px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 26px;
  line-height: 1.5;
  color: ${color('turquoise-blue-100')};

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const TextTop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 15px;
  width: 100%;
`;

const ScrollArrow = styled.a`
  position: absolute;
  bottom: 65px;
  transform: translateX(-50%);
  border: 0;
  background-color: transparent;
  transition: transform .3s ease-in-out;
  cursor: pointer;
  
  &:hover {
    transform: translateX(-50%) translateY(10%);
  }
  
  @media (min-width: 768px) {
    bottom: 145px;
  } 
`;

const HomeHero = ({ text, navLinks, homeLink = '/' }) => (
  <HeroWrapper>
    <Header>
      <Link to={homeLink} css={{ lineHeight: 0 }}>
        <span className="visually-hidden">New Normal</span>
        <Logo src={logo} alt="" />
      </Link>
      <MainNav navLinks={navLinks} />
    </Header>
    <Heading>
      <TextTop>
        {text ? (
          <Title>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Title>
        ) : (
          <React.Fragment>
            <Title>
              You have a project deadline
              <br />
              Your software team is slammed
            </Title>
            <Title as="h2" css={{ color: color('white') }}>
              Your solution is New Normal
            </Title>
          </React.Fragment>
        )}
      </TextTop>
      <ScrollArrow href="#clients">
        <img src={scrollArrow} alt="" />
      </ScrollArrow>
    </Heading>
  </HeroWrapper>
);

export default HomeHero;
