import React from 'react';
import styled from 'styled-components';
import hex2rgba from 'hex2rgba';
import { color, themeColor } from '../../styles/theme';
import { MemberWithLink } from './MemberLink';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  
  &:hover {
    .image-wrapper::before {
      mix-blend-mode: screen;
    }

    .member-name {
      bottom: -55px;
      opacity: 1;
    }
  }
  
  a {
    display: inherit;
    
    &:link,
    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 100%;
  border-radius: 50%;
  width: 100%;
  background-color: ${themeColor('primary')};
  transition: all .4s;
  
  &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-color: ${hex2rgba(themeColor('primary'), 0.75)};
      transition: all .4s;
    }
  
  @supports (mix-blend-mode: color) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        ${themeColor('primary')},
        ${themeColor('primary')}),
        linear-gradient(
        to bottom,
        ${color('white')},
        ${color('white')});
      background-color: ${hex2rgba(themeColor('primary'), 0.85)};
      mix-blend-mode: color;
      transition: all .4s;
    }
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${({ collapsed }) => collapsed ? '-1' : '0'};
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  margin-top: 10px;
  
  @media (min-width: 768px) {
    position: absolute;
    bottom: 50%;
    left: 50%;
    z-index: 5;
    transform: translateX(-50%);
    opacity: 0;
    transition: all .4s ease-in-out;
  }
`;

const Name = styled.span`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  color: ${color('white')};
  
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const Position = styled.span`
  font-size: 10px;
  line-height: 1.5;
  color: ${color('white')};
  
  @media (min-width: 768px) {
    font-size: 16px;
    white-space: nowrap;
  }
`;

const Dummy = styled.div``;

export default ({ data, collapsed }) => {
  const {
    fullname,
    display_name: name,
    position,
    picture,
    linkedin,
  } = data;
  const Outer = linkedin ? MemberWithLink : Dummy;

  return (
    <Wrapper>
      <Outer link={linkedin}>
        <ImageWrapper className="image-wrapper" collapsed={collapsed}>
          {picture && (
            <img src={picture.childImageSharp.resize.src} alt={`${fullname}`} />
          )}
        </ImageWrapper>
        <Content className="member-name">
          <Name>{name}</Name>
          <Position>{position}</Position>
        </Content>
      </Outer>
    </Wrapper>
  );
};
